<template>
  <div id="wish-view">
    <section class="bg-light">
      <main></main>
      <div class="col-md-12">
        <div class="findgft">
          <h1 class="display-4">Wishlist</h1>
        </div>
      </div>
      <div class="container-fluid d-flex justify-content-center">
        <div class="row">
          <div class="col-md-4">
            <router-link to="/my_occasion">
              <img
                class="img-fluid text-center"
                style="width: 60%;"
                src="@/assets/site/images/my_wishlist.png"
                alt=""
              />
            </router-link>
          </div>
          <div class="col-md-4">
            <router-link to="/friends_list">
              <img
                class="img-fluid text-center"
                style="width: 60%;"
                src="@/assets/site/images/friends_wishlist.png"
                alt=""
              />
            </router-link>
          </div>
          <div class="col-md-4">
            <router-link to="/pet_list">
              <img
                class="img-fluid text-center"
                style="width: 60%;"
                src="@/assets/site/images/pet_wishlist.png"
                alt=""
              />
            </router-link>
          </div>
        </div>
      </div>

      <div class="container">
        <hr />
      </div>
    </section>

    <!-- End Footer -->
  </div>
</template>
<script>
export default {
  name: "WishView",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Explore and manage your wishlist with ease! Access personalized sections for occasions, friends, and pets, all in one place. Enhance your shopping experience and keep track of your favorite items effortlessly!",
      },
      {
        name: "keywords",
        content:
          "wishlist view, Vue.js template, manage wishlist, occasions, friends list, pet wishlist, e-commerce, online shopping, user-friendly interface, personalized shopping, responsive design, favorite products, product organization, intuitive navigation, shopping experience, wishlist management.",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      wishlistproducts: [],
      imageshow: false,
    };
  },
  created() {},
  methods: {
    deleteWishlist(wishlistproduct) {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/userwishlist/deleteProduct";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
          product_id: wishlistproduct.product_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.loader = false;
            this.getWishlistProducts();
          } else {
            this.loader = false;
            this.$swal.fire("Error!", data.message, "error");
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
  },
};
</script>
